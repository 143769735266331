import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return []
  }

  connect() {
  }

  confirm() {
    if (!this.data.has('value')) { return }
    if (this.element.value == this.data.get('value')) { return }

    if (!confirm(this.message())) {
      this.element.value = this.data.get('value')
    }
  }

  message() {
    if (this.data.has('message')) {
      return this.data.get('message')
    } else {
      return 'Weet u zeker dat u dit wilt invoeren?'
    }
  }
}
