import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['number', 'suffix', 'warning', 'id', 'submit', 'client', 'secondStep']

  connect() {
    $(this.clientTarget).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
    if (this.clientChosen()) {
      this.enableSecondStep();
    }
  }

  validate_unique_number(success) {
    jQuery.ajax({
      url: this.data.get('url'),
      data: {
        "project[id]": this.idTarget.value,
        "project[number]": this.numberTarget.value,
        "project[suffix]": this.suffixTarget.value
      },
      success: success
    });
  }
  show_alert() {
    alert(this.data.get('alert'));
  }
  show_warning() {
    jQuery(this.warningTarget).show();
    this.numberTarget.classList.add('error');
  }
  hide_warning() {
    jQuery(this.warningTarget).hide();
    this.numberTarget.classList.remove('error');
  }
  warn_unique_number() {
    this.validate_unique_number((data) => {
      if (data.unique) {
        this.hide_warning();
      } else {
        this.show_warning();
      }
    });
  }
  alert_unique_number(e) {
    if (!this.submitTarget.disabled) {
      e.preventDefault();
      this.submitTarget.disabled = "disabled";

      this.validate_unique_number((data) => {
        if (data.unique) {
          jQuery(this.submitTarget).parents('form').submit();
        } else {
          this.show_warning();
          this.show_alert();
          this.submitTarget.disabled = undefined;
        }
      });
    } else {
      // Allow normal post, check was done already
    }
  }
  clearClient() {
    if (!this.clientTarget) return;

    this.clientTarget.value = '';
  }
  clientChosen() {
    return this.element.querySelectorAll('input[name="choose"]:checked').length > 0;
  }
  enableSecondStep() {
    if (!this.hasSecondStepTarget) return;

    this.secondStepTarget.style.display = 'block';
  }
}
