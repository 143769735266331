import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['employee', 'phoneNumber'];

  connect() {
    this.selectEmployee();
    $(this.employeeTarget).on('select2:select', () => {
      let event = new Event('change', { bubbles: true })
      this.employeeTarget.dispatchEvent(event)
    })
  }

  selectEmployee() {
    let phoneNumber = this.employeeTarget.options[this.employeeTarget.selectedIndex].dataset.phoneNumber;
    this.phoneNumberTarget.value = phoneNumber || '';
  }
}
