import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sensor', 'actuator'];

  initialize() {
    this.toggle_type = this.data.get('type') || 'block';
    this.sensor_class = this.data.get('sensorClass') || 'active';
    this.toggle_text_collapsed = this.data.get('textCollapsed') || undefined;
    this.toggle_text = this.data.get('text') || undefined;
  }

  updateByFocus() {
    if (!this.hasActuatorTarget) return;

    if (document.activeElement == this.sensorTarget) {
      this.show();
    } else {
      this.hide();
    }
  }

  toggle(e) {
    if (!this.hasActuatorTarget) return;

    this.sensorTargets.forEach((item) => {
      item.classList.remove(this.sensor_class);
    });

    this.actuatorTargets.forEach((item) => {
      if (item.style.display == this.toggle_type) {
        item.style.display = 'none';
        if (this.toggle_text) {
          e.target.textContent = this.toggle_text;
        }
      } else {
        item.style.display = this.toggle_type;
        e.target.classList.add(this.sensor_class);
        if (this.toggle_text_collapsed) {
          e.target.textContent = this.toggle_text_collapsed;
        }
      }
    });
  }

  toggleById(e) {
    let id = e.target.dataset.toggleId;

    this.sensorTargets.forEach((item) => {
      item.classList.remove(this.sensor_class);
    });

    this.actuatorTargets.forEach((item) => {
      if (item.id == id) {
        if (item.style.display == this.toggle_type) {
          item.style.display = 'none';
        } else {
          item.style.display = this.toggle_type;
          e.target.classList.add(this.sensor_class);
        }
      } else {
        item.style.display = 'none';
      }
    });
  }

  show() {
    if (!this.hasActuatorTarget) return;

    this.actuatorTargets.forEach((item) => {
      item.style.display = this.toggle_type;
    });
  }

  hide() {
    if (!this.hasActuatorTarget) return;

    this.actuatorTarget.style.display.forEach((item) => {
      item.style.display = 'none';
    });
  }
}
