import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'functionSelect',
    'otherFunctionField',
    'userSelect',
    'toggleNewContactButton',
    'newContactRow',
  ];

  connect() {
    this.functionSelected();
    this.newContact = false;
  }

  toggleNewContact() {
    let newContact = !this.newContact;
    this.newContact = newContact;
    this.newContactRowTarget.style.display = newContact ? 'block' : 'none';
    this.userSelectTarget.parentNode.parentNode.style.display = newContact
      ? 'none'
      : 'block';
    this.userSelectTarget.value = '';
  }

  userSelected() {
    var mapping = {
      project_manager: 'foreman',
      planner: 'planner',
    };

    var contactFunction = mapping[this.selectedUserRole()] || 'other';

    for (var i = 0; i < this.functionSelectTarget.options.length; i++) {
      if (this.functionSelectTarget.options[i].value == contactFunction) {
        this.functionSelectTarget.options[i].selected = true;
        break;
      }
    }

    this.functionSelected();
  }

  functionSelected() {
    if (this.selectedFunction() == 'other') {
      this.otherFunctionFieldTarget.removeAttribute('disabled');
      this.otherFunctionFieldTarget.parentNode.parentNode.style.display =
        'block';
    } else {
      this.otherFunctionFieldTarget.setAttribute('disabled', 'disabled');
      this.otherFunctionFieldTarget.parentNode.parentNode.style.display =
        'none';
    }
  }

  selectedUserRole() {
    return this.userSelectTarget.options[this.userSelectTarget.selectedIndex]
      .dataset.role;
  }

  selectedFunction() {
    return this.functionSelectTarget.options[
      this.functionSelectTarget.selectedIndex
    ].value;
  }
}
