import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'name', 'subdomain' ]

  update() {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', '/tenant_subdomains.json');
    xhr.onload = () => {
      console.log(xhr.status)
      console.log(xhr.responseType)
      if (xhr.status === 200) {
        this.subdomainTarget.value = JSON.parse(xhr.responseText).subdomain
      }
    }
    xhr.send(this.data());
  }

  data() {
    let data = new FormData();
    data.append('authenticity_token', this.authenticity_token())
    data.append('name', this.name())
    return data
  }

  authenticity_token() {
    return this.element.querySelector('input[name="authenticity_token"]').value
  }

  name() {
    return this.nameTarget.value
  }
}
