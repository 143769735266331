import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'employeeInput', 'hourRateInput' ]
  }

  initialize() {
    
  }

  connect() {
    this.update()
  }

  update() {
    let selectedOption = this.employeeInputTarget.options[this.employeeInputTarget.selectedIndex]
    let selectedRate = selectedOption.dataset.hourRate
    let defaultRate = this.hourRateInputTarget.dataset.defaultRate
    this.hourRateInputTarget.value = selectedRate || defaultRate
  }
}
