import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'priceCurrent', 'price', 'remainder' ]
  }

  connect() {
    this.update()
  }

  update() {
    var priceCurrentTotals = this.element.querySelectorAll('[data-check-price-current]')
    var priceTotals = this.element.querySelectorAll('[data-check-price]')

    var priceCurrent = 0;
    var price = 0;

    for (var i = 0; i < priceCurrentTotals.length; i++) {
      var number = priceCurrentTotals[i].innerHTML.trim().replace(/\./g, '').replace(/,/g, '.')
      if (number != '') {
        priceCurrent += parseFloat(number)
      }
    }

    for (var i = 0; i < priceTotals.length; i++) {
      var number = priceTotals[i].innerHTML.trim().replace(/\./g, '').replace(/,/g, '.')
      if (number != '') {
        price += parseFloat(number)
      }
    }

    let remainder = this.overallTotal() - priceCurrent;

    this.priceCurrentTarget.innerHTML = priceCurrent.toLocaleString('nl-NL', { minimumFractionDigits: 2 })
    this.priceTarget.innerHTML = price.toLocaleString('nl-NL', { minimumFractionDigits: 2 })
    this.remainderTarget.innerHTML = remainder.toLocaleString('nl-NL', { minimumFractionDigits: 2 })
  }

  overallTotal() {
    return this.data.has('overallTotal') ? parseFloat(this.data.get('overallTotal')) : 0
  }
}
