import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'field', 'value', 'toggler', 'element', 'form' ]
  }

  initialize() {
    if (!this.data.get('status')) {
      this.data.set('status', 'static');
    }
  }
  
  connect() {
    this.fieldTargets.forEach((item) => { item.style.display = 'none' })
    this.initialTogglerText = this.togglerTarget.innerHTML
  }

  toggle() {
    if (this.data.get('status') == 'static') {
      this.fieldTargets.forEach((item) => { item.style.display = 'block' })
      this.valueTargets.forEach((item) => { item.style.display = 'none' })
      this.data.set('status', 'edit');
      this.togglerTarget.innerHTML = this.togglerTarget.dataset.cancelText || 'Annuleer';
    } else {
      this.fieldTargets.forEach((item) => { item.style.display = 'none' })
      this.valueTargets.forEach((item) => { item.style.display = 'block' })
      this.data.set('status', 'static');
      this.togglerTarget.innerHTML = this.initialTogglerText || 'Bewerk';
    }
  }
}
