import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [ "form", "field" ]
  }

  connect() {
  }

  confirmIfValueDifferent(e) {
    if (!this.data.has("value") || this.data.get("value") === "") { return }
    e.preventDefault()

    this.normalizePhoneNumber(this.fieldTarget.value, (number) => {
      console.log(number)
      if (number != this.data.get("value")) {
        if (confirm(this.data.get("message"))) {
          this.formTarget.submit()
        }
      } else {
        this.formTarget.submit()
      }
    })
  }

  normalizePhoneNumber(number, callback) {
    var request = new XMLHttpRequest()
    var data = new FormData()

    data.append("number", number)

    request.open("POST", this.data.get("url"))
    request.setRequestHeader("X-Requested-With", "XMLHttpRequest")
    request.onload = () => {
      if (request.status === 200) {
        callback(JSON.parse(request.responseText).normalized)
      } else {
        callback(null)
      }
    }
    request.send(data)
  }
}
