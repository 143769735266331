import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'form', 'result', 'hiddenInputs', 'errors', 'submit' ]
  }

  connect() {
    this.update()
  }

  update() {
    let self = this
    var form = this.formTarget
    var data = new FormData(form)
    var url = this.data.get('url')
    
    var request = new XMLHttpRequest()
    request.open('POST', url)
    request.onload = function() {
      if (request.status === 200) {
        let data_for_elements = JSON.parse(request.responseText)
        self.reset()
        data_for_elements.forEach(function(data_for_element) {
          self.update_element(data_for_element)
        })
      } else {
        // handle error
      }
    }
    request.send(data)
  }

  reset() {
    let self = this
    let arr = [...self.formTarget.querySelectorAll('[data-guide-input]')]
    arr.forEach(function(input_element) {
      self.hide(input_element)
    })
    self.errorsTarget.innerHTML = ''
    self.submitTarget.disabled = true
    self.hide(this.resultTarget)
  }

  update_element(data) {
    if (data.key == null) {
      this.update_result_element(data)
    } else {
      this.update_input_element(data)
    }
  }

  update_result_element(data) {
    let self = this
    this.show(this.resultTarget)

    data.display_values.forEach(function(display_value) {
      self.resultTarget.querySelector(`[data-result-key="${display_value[0]}"] [data-result-value]`).innerHTML = display_value[1]
    })

    var hidden_inputs = ''
    data.form_values.forEach(function(hidden_input) {
      hidden_inputs += `<input type="hidden" name="${hidden_input[0]}" value="${hidden_input[1]}" />`
    })
    self.hiddenInputsTarget.innerHTML = hidden_inputs

    if (data.errors.length > 0) {
      data.errors.forEach(function(error) {
        self.errorsTarget.appendChild(self.build_error_node(error))
      })

      self.submitTarget.disabled = true
    } else {
      self.submitTarget.disabled = false
    }
  }

  update_input_element(data) {
    let element = this.formTarget.querySelector(`[data-guide-input][name="${data.key}"]`)
    if (element == null) { return }
    let wrapper = element.parentNode.parentNode
    wrapper.parentNode.appendChild(wrapper)

    this.show(element)

    var question = data.question
    if (data.required == true) {
      question += ' *'
    }
    wrapper.querySelector('label').innerHTML = question
    var options = ''
    var selected = false
    data.options.forEach(function (option) {
      let text = option[0]
      let value = option[1]
      var selected_attribute = ''
      if (!selected && value == data.value) {
        selected_attribute = ' selected'
        selected = true
      }
      let value_attribute = (value == null) ? '' : ` value="${value}"`

      options += `<option${value_attribute}${selected_attribute}>${text}</option>`
    })
    element.innerHTML = options
    let instruction = data.instruction;

    if(instruction == null) {
      instruction = ''
    }
    element.parentNode.querySelector('[data-guide-instruction]').innerHTML = instruction
  }

  hide(element) {
    if (element.nodeName == 'SELECT') {
      element.disabled = false
      let wrapper = element.parentNode.parentNode
      wrapper.style.display = 'none'
    } else {
      element.disabled = true
      element.style.display = 'none'
    }
  }
  
  show(element) {
    if (element.nodeName == 'SELECT') {
      element.disabled = false
      let wrapper = element.parentNode.parentNode
      wrapper.style.display = 'block'
    } else {
      element.disabled = false
      element.style.display = 'block'
    }
  }

  build_error_node(error) {
    let error_node = document.createElement('span')
    error_node.classList.add('error')
    error_node.style.fontStyle = 'normal'
    error_node.style.fontWeight = 700
    error_node.style.fontSize = '0.9em'
    error_node.innerHTML = error
    return error_node
  }
}
