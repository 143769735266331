import { Controller } from 'stimulus'
import { Timeline, DataSet } from 'vis-timeline/standalone';

export default class extends Controller {
  static targets = ['column', 'container'];

  initialize() {
    // Create a DataSet (allows two way data-binding)
    this.items = new DataSet(JSON.parse(this.data.get('items')));

    // Create groups
    this.groups = new DataSet(JSON.parse(this.data.get('projects')));

    // Configuration for the Timeline
    this.options = {
      zoomMin: 900000, // 15 minutes in ms
      orientation: { axis: 'both' },
    } // default options

    if (this.data.has('options')) {
      let extraOptions = JSON.parse(this.data.get('options'));
      this.options = Object.assign(this.options, extraOptions);
    }

    // Create timeline
    this.timeline = new Timeline(this.containerTarget);
    this.timeline.setOptions(this.options);
    this.timeline.setGroups(this.groups);
    this.timeline.setItems(this.items);

    this.timeline.on('rangechange', () => {
      this.resizeColumns();
    });

    this.timeline.on('currentTimeTick', () => {
      var date = new Date();
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      // if the browser gets performance issues, change the value 19 (including seconds) to 16 (excluding seconds)
      var now = local.toJSON().slice(0, 19).split('T').join(' ')

      var new_items = JSON.parse(this.data.get('items')).map(item => {
        item.end == null ? item.end = now : null;
        return item;
      })
      this.timeline.setItems(new DataSet(new_items));
    });
  }

  connect() {
    setTimeout(() => {
      this.resizeColumns();
    }, 200);
  }

  disconnect() {
    this.timeline.destroy();
  }

  resizeColumns() {
    let items = document.querySelectorAll('.vis-label');
    this.columnTargets.forEach((item, i) => {
      let itemHeight = items[i].offsetHeight;
      item.style.height = `${Math.ceil(itemHeight)}px`;
    });
  }
}
