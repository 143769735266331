import { Controller } from 'stimulus'

import { Loader } from '@googlemaps/js-api-loader';
const googleMapsApi = new Loader({
  apiKey: 'AIzaSyCZlUckADtCnJUQWIeaECDqyr77dQcWFdc',
  version: 'weekly'
});

import LocationPicker from "location-picker"

export default class extends Controller {
  static get targets() {
    return [ 'map', 'latitude', 'longitude' ]
  }

  connect() {
    googleMapsApi
      .load()
      .then(() => {
        this.lp = new LocationPicker(this.mapTarget, {
          setCurrentPosition: false,
          lat: this.hasCoordinates() ? parseFloat(this.data.get("latitude")) : 52.132633,
          lng: this.hasCoordinates() ? parseFloat(this.data.get("longitude")) : 5.291266
        }, {
            zoom: this.hasCoordinates() ? 15 : 7
        });
        google.maps.event.addListener(this.lp.map, 'idle', (event) => {
          this.updateOutput()
        });
      })
  }

  updateOutput() {
    let location = this.lp.getMarkerPosition()
    this.latitudeTarget.value = location.lat
    this.longitudeTarget.value = location.lng
  }

  hasCoordinates() {
    return this.data.has('latitude') &&
        this.data.has('longitude') &&
        this.data.get('latitude') !== '' &&
        this.data.get('longitude') !== ''
  }
}
