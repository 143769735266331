import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return [ 'companyId', 'contents' ]
  }

  connect() {
    this.select2(this.companyIdTarget);
  }

  update() {
    var selected_options = this.companyIdTarget.selectedOptions;

    if (selected_options.length == 0) {
      this.element.querySelectorAll('[data-company-id]').forEach(function(element) {
        jQuery(element).show();
      });
    } else {
      this.element.querySelectorAll('[data-company-id]').forEach(function(element) {
        jQuery(element).hide();
      });

      for (var i = 0; i < selected_options.length; i++) {
        var option = selected_options[i];
        var element = this.element.querySelector('[data-company-id="' + option.value + '"]');
        if (element !== undefined) {
          jQuery(element).show();
        }
      }
    }
  }

  select2(target) {
    let controller = this;
    let $target = jQuery(target);

    /* FIXME terrible hack to prevent empty select2 */
    setTimeout(function() {
      $target.select2(select2_options);
      /* convert select2 events to native change event
      which stimulus can handle */
      $target.on('select2:select select2:unselect', function() {
        target.dispatchEvent(new Event('change', { bubbles: true }))
      });

      controller.update();
    }, 100);
  }
}
